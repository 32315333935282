<script setup>
import { ref, computed, reactive, onMounted } from 'vue';
import { confirmEmail } from '@/api/auth/auth';
import { useRouter } from 'vue-router';
import { changePasswordData } from '@/composables/changePassword';

const router = useRouter();
const {
    getUid,
    getEmail,
    setToken
} = changePasswordData();
const verificationCode = ref(null);
const isLoading = ref(false);
const codeRegex = /^([0-9]{6})$/;

const validCode = computed(() => codeRegex.test(verificationCode.value));
const matchingCode = ref(null); 
const codeInvalidText = computed(() => {
    if (!validCode.value)
        return 'Код должен состоять из 6 цифр';

    return null
});
const buttonText = computed(() => (isLoading.value) ? 'Подтверждение...' : 'Подтвердить');

const error = reactive({
    show: false,
    text: 'Некорректный или истекший проверочный код'
});

async function acceptVerificationCode() {

    if (!validCode.value)
        return;

    if (isLoading.value)
        return;

    isLoading.value = true;
    
    let result = await confirmEmail(getUid(), getEmail(), `${verificationCode.value}`);

    if (result.success) {
        setToken(result.data.token);
        router.push('/login/new-pass');
    } else {
        error.show = true;
        error.text = result.errorDescription;
    }
    isLoading.value = false;   
}

onMounted(() => {
    if (!getUid() || !getEmail()) {
        router.push('/login');
    }
});
</script>

<template>
    <div class="confirm-email">
        <div class="confirm-email__content d-flex flex-column
            justify-content-between">
            <h5 class="confirm-email__title text-center ">
                Введите код подтверждения
            </h5>
            <div class="confirm-email__code-input form-floating">
                <input 
                    class="confirm-email__input form-control" 
                    id="verificationCode" 
                    v-model="verificationCode" 
                    type="number"
                    placeholder="Код подтверждения"
                    autofocus/>
                <label 
                    for="verificationCode"
                    class="confirm-email__label">
                    Код подтверждения
                </label>
            </div>
            <button 
                class="confirm-email__buton btn btn-primary btn-lg" 
                type="button" 
                @click="acceptVerificationCode">
                <span v-if="isLoading" 
                    class="confirm-email__spinner spinner-grow spinner-grow-sm" 
                    role="status" 
                    aria-hidden="true"/>
                {{ buttonText }}
            </button>
        </div>
        <p v-show="!validCode || !matchingCode"
            class="confirm-email__alert mb-0 text-center text-danger pt-1">
            {{ codeInvalidText }}
        </p>
        <p v-show="error.show"
            class="confirm-email__error text-center text-danger">
            {{ error.text }}
        </p>
    </div>
</template>

<style scoped>
.confirm-email {
    height: 200px;
    width: 100%;
}

.confirm-email__content {
    height: 88%;
}

.confirm-email__alert, .confirm-email__error {
    font-size: clamp(0.75rem, 0.681rem + 0.37vw, 1.125rem);
    line-height: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (min-width: 860px) {
    .confirm-email {
        height: 250px !important;
    }
}
</style>