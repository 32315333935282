import httpClient from '@/api/httpService.js';


function sendCode(fields){
    return httpClient.post(
        `${process.env.VUE_APP_AUTH_SERVICE_URL}/api/sign-in-data/send-code`,
        {
            "email":fields.login
        }
    )
    .then((response) => {
        return response.data;
    })
    .catch((e) => {
        return e
    });
}

function signInEmail(fields) {
    let bodyFormData = new FormData();
    bodyFormData.append("email", fields.login);
    bodyFormData.append("password", fields.password);

    return httpClient.post(
        `${process.env.VUE_APP_AUTH_SERVICE_URL}/api/sign-in-data/by-email`, 
        bodyFormData
    )
    .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('user.token', response.data.token);
        return response.data;
    })
    .catch((e) => {
        return e;
    });
}

function signInEmailAndCode(fields) {
    return httpClient.post(
        `${process.env.VUE_APP_AUTH_SERVICE_URL}/api/sign-in-data/by-email-and-code`,
        {
            "email":fields.login,
            "validationCode":fields.verificationCode
        }
    )
    .then((response) => {
        return response.data;
    })
    .catch((e) => {
        return e
    });
}

function changeInfo(userId, token, data) {
    return httpClient.post(
        `${process.env.VUE_APP_AUTH_SERVICE_URL}/api/change-info`, 
        data, 
        {
            params: {
                uid: userId,
                token: token
            }
        }
    )
    .then((response) => {
        return response.data;
    })
    .catch((e) => {
        return e
    });
}

function signIn(token) {
    let bodyFormData = new FormData();
    bodyFormData.append("jwtToken", token);

    return httpClient(
        {
            method: "post",
            url: "/api/jwt/org-user/sign-in",
            data: bodyFormData,
        }
    )
    .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('user.token', token);
        return response.request.status;
    })
    .catch((e) => {
        return e.request.status;
    });
}

function signOutUser() {
    return httpClient(
        {
            method: "get",
            url: "/api/jwt/org-user/sign-out",
        }
    )
    .then(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('user.token');
    });
}

function checkUser(token) {
    return httpClient(
        {
            method: "post",
            url: "/api/get-user",
            data: {
                token
            }
        }
    )
    .then((response) => {
        return response.data
    })
    .catch((e) => {
        return e;
    });
}

function checkExists(data) {
    return httpClient(
        {
            method: "post",
            url: "/api/jwt/user/check-exists",
            data: data,
        }
    )
    .then((response) => {
        return response.data
    })
    .catch((e) => {
        return e
    })
}

function changeByEmailWithPassword(fields) {
    let bodyFormData = new FormData();
    bodyFormData.append("email", fields.login);
    bodyFormData.append("password", fields.password);

    return httpClient(
        {
            method:"post",
            url:`${process.env.VUE_APP_AUTH_SERVICE_URL}/api/change-info/by-email-with-password`,
            data:bodyFormData
        }
    )
    .then((response) => {
        return response.data;
    })
    .catch((e) => {
        return e;
    });
}

function changeByEmail(login) {
    let bodyFormData = new FormData();
    bodyFormData.append("email", login);
    return httpClient(
        {
            method:"post",
            url:`${process.env.VUE_APP_AUTH_SERVICE_URL}/api/change-info/by-email`,
            data: bodyFormData
        }
    )
    .then((response) => {
        return response.data;
    })
    .catch((e) => {
        return e;
    });
}

function confirmEmail(userId, email, validationCode) {
    let bodyFormData = new FormData();
    bodyFormData.append("uid", userId);
    bodyFormData.append("email", email);
    bodyFormData.append("validationCode", validationCode);

    return httpClient.post(
        `${process.env.VUE_APP_AUTH_SERVICE_URL}/api/change-info/confirm-email`, 
        bodyFormData
    )
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        return error;
    });
}

export {
    signOutUser,
    signIn,
    checkUser,
    checkExists,
    sendCode,
    signInEmailAndCode,
    signInEmail,
    changeByEmailWithPassword,
    changeByEmail,
    confirmEmail,
    changeInfo,
};