import { ref } from 'vue';

const email = ref(null);

const token = ref(null);

const uid = ref(null);

export function changePasswordData() {

    const setEmail = (_email) => email.value = _email;

    const getEmail = () => email.value;

    const setToken = (_token) => token.value = _token;

    const getToken = () => token.value;

    const setUid = (_uid) => uid.value = _uid;

    const getUid = () => uid.value;

    return {
        setEmail,
        getEmail,
        setToken,
        getToken,
        setUid,
        getUid
    }
}